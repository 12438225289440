var Handlebars = require("../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"hf-widget-container\" id=\"hf-"
    + alias4(((helper = (helper = lookupProperty(helpers,"token") || (depth0 != null ? lookupProperty(depth0,"token") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"token","hash":{},"data":data,"loc":{"start":{"line":1,"column":40},"end":{"line":1,"column":49}}}) : helper)))
    + "\">\n    <img class=\"hf-badge-img\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"medalImage") || (depth0 != null ? lookupProperty(depth0,"medalImage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"medalImage","hash":{},"data":data,"loc":{"start":{"line":2,"column":35},"end":{"line":2,"column":49}}}) : helper)))
    + "\" />\n    <span class=\"hf-hi-info\">\n        <div data-popover-target=\"hf-hi-popover\" class=\"hf-info-btn\">i</div>\n    </span>\n    <a href=\"https://myhappyforce.com/?utm_source="
    + alias4(((helper = (helper = lookupProperty(helpers,"token") || (depth0 != null ? lookupProperty(depth0,"token") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"token","hash":{},"data":data,"loc":{"start":{"line":6,"column":50},"end":{"line":6,"column":59}}}) : helper)))
    + "&amp;utm_medium=widget&amp;utm_campaign=widget-click&amp;utm_content=logo\" target=\"_blank\">\n        <img class=\"hf-logo\" alt=\"Happyforce\" src=\"https://myhappyforce.com/wp-content/uploads/2019/12/logo_happyforce_horizontal.png\">\n    </a>\n    <div class=\"hf-badge-info\">\n        <div class=\"hf-widget "
    + alias4(((helper = (helper = lookupProperty(helpers,"status") || (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"status","hash":{},"data":data,"loc":{"start":{"line":10,"column":30},"end":{"line":10,"column":40}}}) : helper)))
    + "\">\n            <div class=\"hf-badge-text\">\n                <div class=\"hf-badge-title\">"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"t") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</div>\n                <div class=\"hf-badge-rank\">"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"t") : depth0)) != null ? lookupProperty(stack1,"subtitle") : stack1), depth0))
    + "</div>\n                <div class=\"hf-hi\">\n                    <span class=\"value\">\n                        <span class=\"number\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"HI") || (depth0 != null ? lookupProperty(depth0,"HI") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"HI","hash":{},"data":data,"loc":{"start":{"line":16,"column":45},"end":{"line":16,"column":51}}}) : helper)))
    + "</span>\n                        <span class=\"text\">hi</span>\n                    </span>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <template data-popover=\"hf-hi-popover\" style=\"display:none\">\n        <p>"
    + ((stack1 = alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"t") : depth0)) != null ? lookupProperty(stack1,"hi_info") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n        <p>\n            <a data-popover-target=\"hf-hi-popover\" href=\"https://myhappyforce.com/2020/03/05/insignia-happyforce/?utm_source="
    + alias4(((helper = (helper = lookupProperty(helpers,"token") || (depth0 != null ? lookupProperty(depth0,"token") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"token","hash":{},"data":data,"loc":{"start":{"line":27,"column":125},"end":{"line":27,"column":134}}}) : helper)))
    + "&amp;utm_medium=widget&amp;utm_campaign=widget-click&amp;utm_content=info\" target=\"_blank\">\n                "
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"t") : depth0)) != null ? lookupProperty(stack1,"hi_info_plus") : stack1), depth0))
    + "\n            </a>\n        </p>        \n    </template>\n</div>";
},"useData":true});