export function createElement(type, attrs, HTML) {
    var element = document.createElement(type);
    attrs && Object.keys(attrs).forEach(key => {
        element[key] = attrs[key];
    })
    element.innerHTML = HTML;
    return element;
}

export function formatNumber(number, decimals=0) {
    return number.toFixed(decimals);
}

export function getMedal(ranking) {
    var rankingImages = {
        TOP_10: '/defaultImages/ranking_1.svg',
        TOP_20: '/defaultImages/ranking_2.svg',
        TOP_30: '/defaultImages/ranking_3.svg',
        TOP_40: '/defaultImages/ranking_4.svg',
        TOP_50: '/defaultImages/ranking_5.svg',
        TOP_75: '/defaultImages/ranking_6.svg',
        NO_TOP: '/defaultImages/ranking_none.svg',
        UNKNOWN: '/defaultImages/ranking_none.svg'
    };
    return `https://assets.myhappyforce.com${rankingImages[ranking]}`;
}

export function getMedalPercent(ranking) {
    var rankingInfo = {
        TOP_10: 10,
        TOP_20: 20,
        TOP_30: 30,
        TOP_40: 40,
        TOP_50: 50,
        TOP_75: 75,
        NO_TOP: null,
        UNKNOWN: null
    };
    return rankingInfo[ranking];
}

export function findArea(areas, area) {
    return areas.filter(a => (a.id === area))[0];
}

if (!('remove' in Element.prototype)) {
    Element.prototype.remove = function() {
        if (this.parentNode) {
            this.parentNode.removeChild(this);
        }
    };
}