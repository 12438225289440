import 'babel-polyfill';
import 'whatwg-fetch';

import './style.less';
import Popover from './popover';
import { createElement, formatNumber, getMedal, getMedalPercent, findArea } from './utils.js';
import labels from './i18n';

var template = require("./index.hbs");

window.hfWidget = function (container, settings) {
    if (!settings || !settings.token)  return;

    let token = settings.token;
    const hierarchy = settings.hierarchy;
    let lang = settings.lang || (() => { 
        const navLang = (window.navigator.userLanguage || window.navigator.language);
        return navLang.indexOf("-") > -1 && navLang.split("-")[0] || navLang; 
    })();

    const script = document.getElementById(container);

    function generate(json) {
        const HI = formatNumber(json.hi);

        if (hierarchy) token += `.${hierarchy}`;
        const percent = getMedalPercent(json.ranking);

        if (!labels[lang]) lang = 'en';
        const t = {
            ...labels[lang],
            subtitle: (percent) ? labels[lang].subtitle.replace('{{percent}}', percent) : labels[lang].no_top
        };

        const HTML = template({
            medalImage: getMedal(json.ranking),
            t,
            HI,
            token
        });

        const maxWidth = script.offsetWidth - ((script.offsetWidth * 40)/100);
        script.appendChild(createElement("style",null, `.hf-popover { max-width: ${maxWidth}px;}`));
        script.appendChild(createElement("div", {className: `hf-widget-content ${json.valuation}`}, HTML));   
        

        // Popover
        const trigger = script.getElementsByClassName('hf-info-btn')[0];        
        let popover = new Popover(trigger, { position: 'right' });
        trigger.addEventListener('click', () => popover.toggle());
    }

    const URL = `https://assets.myhappyforce.com/widgetData/${token}.json`;
    fetch(URL)
        .then(function(response) { return response.json();})
        .then(json => {
            generate(hierarchy ? findArea(json.areas, hierarchy) : json);
        });

};