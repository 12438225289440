function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    const html = document.documentElement;
    return rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || html.clientHeight) &&
      rect.right <= (window.innerWidth || html.clientWidth);
  }
  
  export default class Popover {
    constructor(trigger, { className = 'hf-popover' }) {
      this.trigger = trigger;
      this.position = 'left';
      this.className = className;
      this.orderedPositions = ['left'];
  
      const popoverTemplate = document.querySelector(`[data-popover=${trigger.dataset.popoverTarget}]`);
      this.popover = document.createElement('div');
      this.popover.innerHTML = popoverTemplate.innerHTML;
  
      Object.assign(this.popover.style, {
        position: 'fixed'
      });
  
      this.popover.classList.add(className);
  
      this.handleWindowEvent = () => {
        if (this.isVisible) {
          this.show();
        }
      };
  
      this.handleDocumentEvent = (evt) => {
        if (this.isVisible && evt.target !== this.trigger && evt.target !== this.popover) {
          this.popover.remove();
        }
      };
    }
  
    get isVisible() {
      return document.body.contains(this.popover);
    }
  
    show() {
      document.addEventListener('click', this.handleDocumentEvent);
  
      document.body.appendChild(this.popover);
  
      const { top: triggerTop, left: triggerLeft } = this.trigger.getBoundingClientRect();
      const { offsetWidth: popoverWidth } = this.popover;
  
      const positionIndex = this.orderedPositions.indexOf(this.position);
  
      const positions = {       
        left: {
            name: 'left',
            top: triggerTop, 
            left: triggerLeft - popoverWidth
        }
      };
  
      const position = this.orderedPositions
        .slice(positionIndex)
        .concat(this.orderedPositions.slice(0, positionIndex))
        .map(pos => positions[pos])
        .find(pos => {
          this.popover.style.top = `${pos.top}px`;
          this.popover.style.left = `${pos.left}px`;
          return isInViewport(this.popover);
        });
  
      this.orderedPositions.forEach(pos => {
        this.popover.classList.remove(`${this.className}--${pos}`);
      });
  
      if (position) {
        this.popover.classList.add(`${this.className}--${position.name}`);
      }
    }
  
    destroy() {
      this.popover.remove();  
      document.removeEventListener('click', this.handleDocumentEvent);
    }
  
    toggle() {
      if (this.isVisible) {
        this.destroy();
      } else {
        this.show();
      }
    }
  }