export default {
    en: {
        title: "Congratulations!",
        subtitle: "Company in the top {{percent}}%",
        no_top: "Company listening to its employees",
        hi_info: "<strong>Happiness Index (HI)</strong> is a number from 0 to 100 that reflects the level of motivation and satisfaction of employees in an organization",
        hi_info_plus: "See more"
    },
    es: {
        title: "¡Enhorabuena!",
        subtitle: "Empresa en el top {{percent}}%",
        no_top: "Empresa escuchando a sus empleados",
        hi_info: "<strong>Happiness Index (HI)</strong> es un número del 0 al 100 que refleja el nivel de motivación y satisfacción de los trabajadores de una organización",
        hi_info_plus: "Ver más"
    },
    az: {
        title: "Müqavilə",
        subtitle: "Ən yaxşı şirkət {{percent}}%",
        no_top: "Şirkət öz işçilərini dinləyir",
        hi_info: "<strong> Xoşbəxtlik İndeksi (HI) </strong> bir təşkilatdakı işçilərin motivasiya və məmnunluq səviyyəsini əks etdirən 0-dan 100-dək bir rəqəmdir",
        hi_info_plus: "Daha çox məlumata"
    },
    ca: {
        title: "Enhorabona!",
        subtitle: "Empresa a la part superior del {{percent}}%",
        no_top: "Empresa que escolta els seus empleats",
        hi_info: "<strong> Índex de felicitat (HI) </strong> és un número de 0 a 100 que reflecteix el nivell de motivació i satisfacció dels empleats d'una organització",
        hi_info_plus: "Veure més"
    },
    ca: {
        title: "Gratulacje!",
        subtitle: "Firma na szczycie {{percent}}%",
        no_top: "Firma słucha swoich pracowników",
        hi_info: "<strong> Indeks szczęścia (HI) </strong> to liczba od 0 do 100, która odzwierciedla poziom motywacji i zadowolenia pracowników w organizacji",
        hi_info_plus: "Zobacz więcej"
    },
    pt: {
        title: "Congratulations!",
        subtitle: "Empresa entre os melhores {{percent}}%",
        no_top: "Empresa ouvindo seus funcionários",
        hi_info: "<strong> Índice de Felicidade (IA) </strong> é um número de 0 a 100 que reflete o nível de motivação e satisfação dos funcionários de uma organização",
        hi_info_plus: "Veja mais"
    },
    pl: {
        title: "Kontürülasyonlar!",
        subtitle: "En üstteki şirket {{percent}}%",
        no_top: "Çalışanlarını dinleyen şirket",
        hi_info: "<strong> Mutluluk Endeksi (HI) </strong>, bir kuruluştaki çalışanların motivasyon ve memnuniyet düzeyini yansıtan 0 ile 100 arasında bir sayıdır",
        hi_info_plus: "Daha fazlasına bakın"
    },
    
};